import { useRouter } from 'next/router';
import MenuItem from './MenuItem';

function NavMenu() {
  const router = useRouter();
  return (
    <>
      <MenuItem name="Mál í innheimtu" url="/opinmal" current={router.asPath === '/opinmal'} />
      <MenuItem name="Greidd mál" url="/greiddmal" current={router.asPath === '/greiddmal'} />
      <MenuItem name="Innborganir" url="/innborganir" current={router.asPath === '/innborganir'} />
      <MenuItem name="Innheimtubréf" url="/innheimtubref" current={router.asPath === '/innheimtubref'} />
      <MenuItem name="Samskipti" url="/samskipti" current={router.asPath === '/samskipti'} />
    </>
  );
}
export default NavMenu;
