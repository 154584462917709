import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Typography, Tooltip } from '@mui/material';

import getProduct from 'src/helpers/products';

export default class ProductAvatar extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    showName: PropTypes.bool,
    isClaimCard: PropTypes.bool,
  };

  render() {
    const { type, showName, isClaimCard } = this.props;
    if (!type) {
      return null;
    }
    const product = getProduct(type);
    if (showName) {
      return (
        <Box sx={{ pl: '25px' }} className="no-print">
          <Avatar
            sx={{
              float: 'left',
              ml: '-25px',
              bgcolor: product.color,
              width: '20px',
              height: '20px',
              fontSize: '11px',
              fontWeight: 'normal',
            }}
          >
            {product.code}
          </Avatar>
          <Typography variant="body1">{product.name}</Typography>
        </Box>
      );
    }

    if (isClaimCard) {
      return (
        <Tooltip title={product.name} aria-label={product.name} classes={{ tooltip: 'no-print' }}>
          <Avatar
            sx={{
              float: 'left',
              marginLeft: '0px',
              bgcolor: product.color,
              width: '45px',
              height: '45px',
              fontSize: '20px',
              fontWeight: 'normal',
            }}
            className="no-print"
          >
            {product.code}
          </Avatar>
        </Tooltip>
      );
    }

    return (
      <Avatar
        sx={
          this.props.sx
            ? { ...this.props.sx, bgcolor: product.color }
            : {
                float: 'left',
                marginLeft: '-20px',
                bgcolor: product.color,
                width: '16px',
                height: '16px',
                fontSize: 9,
                fontWeight: 'normal',
              }
        }
        className="no-print"
      >
        {product.code}
      </Avatar>
    );
  }
}

ProductAvatar.defaultProps = {
  type: null,
  showName: true,
  isClaimCard: false,
};
