import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Image from 'next/image';
import { AuthContext } from 'src/context/AuthProvider';
import MotusLogo from 'public/images/motus_logo_hvitt.svg';
import CreateIssueSideBar from 'src/containers/CreateIssueSideBar';
import { CommunicationContext } from 'src/context/CommunicationProvider';
import { PayerPersonalInfoRequest } from 'src/helpers/issueType';
import AccountDialog from 'src/components/Modals/AccountsDialog';

function FooterListItem({ children }) {
  return (
    <Box
      component="li"
      sx={(theme) => ({
        float: 'left',
        borderLeft: '1px rgba(255, 255, 255, .65) solid',
        p: '5px 0px 5px 12px',
        ml: '12px',
        color: '#FFFFFF',
        [theme.breakpoints.down('lg')]: {
          borderLeft: 'none',
          ml: '0px',
          p: '2px 0px',
          float: 'none',
          display: 'block',
        },
        '@media print': {
          float: 'left',
          p: '5px 15px 5px 0px',
          m: '0px',
        },
        '&:last-child': {
          [theme.breakpoints.up('lg')]: {
            borderRight: '1px rgba(255, 255, 255, .65) solid',
            pr: '12px',
          },
        },
      })}
    >
      {children}
    </Box>
  );
}

export default function Footer() {
  const [isIssueDrawerOpen, setIsIssueDrawerOpen] = useState(false);
  const [shouldShowAccountInfo, setShouldShowAccountInfo] = useState(false);

  const communicationContext = useContext(CommunicationContext);
  const user = useContext(AuthContext);

  function handleCloseCreateIssueDrawer() {
    setIsIssueDrawerOpen(false);
  }

  function issuesChangedCallback() {
    communicationContext.refreshIssues();
  }

  function handleCloseAccountInfoDialog() {
    setShouldShowAccountInfo(false);
  }

  const year = new Date().getFullYear();

  return (
    <>
      {isIssueDrawerOpen && (
        <CreateIssueSideBar
          closeCreateIssueDrawer={handleCloseCreateIssueDrawer}
          issuesChangedCallback={issuesChangedCallback}
          preSelectedIssueType={PayerPersonalInfoRequest}
          hasContactInfo={user.settings.hasContactInfo}
        />
      )}
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          p: '45px 25px',
          mx: '-16px',
          marginTop: '336px',
          bgcolor: '#73AF69',
          '@media print': {
            borderTop: '1px solid rgba(224, 224, 224, 1) !important',
            m: '30px 0 10px 0 !important',
            p: '0px',
          },
          [theme.breakpoints.down('md')]: {
            p: '45px 15px',
            marginTop: '50px',
          },
        })}
      >
        <Grid
          container
          spacing={2}
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Grid
            xs={12}
            sm={6}
            md
            className="no-print"
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '20px',
              [theme.breakpoints.down('lg')]: {
                marginRight: 0,
                flexDirection: 'column',
                '& .footerLogo': {
                  marginBottom: '20px',
                },
              },
            })}
          >
            <Image src={MotusLogo} className="footerLogo" alt="Motus ehf." />

            <Box
              component="ul"
              sx={(theme) => ({
                listStyle: 'none',
                color: '#fff',
                fontSize: 15,
                p: 0,
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                  my: '15px',
                  flexDirection: 'column',
                  margin: 0,
                  alignItems: 'flex-start',
                  textAlign: 'left',
                },
                '@media print': { mt: '0px' },
              })}
            >
              <FooterListItem>&copy; {year} Motus ehf.</FooterListItem>
              <FooterListItem>Katrínartúni 4</FooterListItem>
              <FooterListItem>105 Reykjavík</FooterListItem>
              <FooterListItem>Sími 440 7700</FooterListItem>
            </Box>
          </Grid>
        </Grid>
        <AccountDialog handleClose={handleCloseAccountInfoDialog} isOpen={shouldShowAccountInfo} />
      </Box>
    </>
  );
}
